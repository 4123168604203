import React from "react";
import Navbar from "../components/navbar";
import Heading from "../components/heading/heading";
import TeamCard from "../components/teamCard/teamCard";
import Footer from "../components/footer/Footer";
import Seo from "../components/seo";
import imadDp from "../images/Imad.jpeg"
import zainDp from "../images/zain.jpg"

function Team() {
  const cardInfo1 = {
    name: "Imad U Din Ahmad",
    image: imadDp,
    position: "Founder",
    linkedIn: "https://www.linkedin.com/in/imad-u-din-ahmad-075b93176/",
    instagram: "https://instagram.com/imad_ahmad13",
  };

  const cardInfo2 = {
    instagram: "https://instagram.com/syed_zynn",
    name: "Syed Zain",
    image: zainDp,
    position: "General Manager",
  };

  const cardInfo3 = {
    name: "Alan Miller",
    position: "Foreign Sales Manager",
  };
  const cardInfo4 = {
    name: "Amir Aslam",
    position: "Company Secretary",
  };
  return (
    <>
      <Seo title="Team" description="Team page of Dusk Landing" />
      <div>
        <Navbar />
        <div className="container mx-auto p-4 pt-0 flex flex-col">
          <div className="mx-4 mb-4">
            <Heading> Executive Team</Heading>
          </div>
          <div className="w-full flex flex-wrap justify-center">
            <div>
              <TeamCard cardInfo={cardInfo1} />
            </div>
            <div>
              <TeamCard cardInfo={cardInfo2} />
            </div>
            <div>
              <TeamCard cardInfo={cardInfo3} />
            </div>
            <div>
              <TeamCard cardInfo={cardInfo4} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Team;
