// extracted by mini-css-extract-plugin
export var card = "teamCard-module--card--33NIS";
export var imageWrapper = "teamCard-module--imageWrapper--1Jcf_";
export var overlay = "teamCard-module--overlay--3aDGM";
export var bgEffect = "teamCard-module--bgEffect--3JhiP";
export var image = "teamCard-module--image--qZ1Nt";
export var social = "teamCard-module--social--rr14a";
export var cardBody = "teamCard-module--cardBody--1BQot";
export var name = "teamCard-module--name--2IQC6";
export var position = "teamCard-module--position--34-jL";
export var iconButton = "teamCard-module--iconButton--tIeG3";