import React from "react";
import * as classes from "./teamCard.module.css";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaGlobe,
  FaGithub,
  FaYoutube,
  FaEnvelope
} from "react-icons/fa";
import defaultAvtar from "../../images/default-avtar.jpg"

function TeamCard({ cardInfo }) {
  const {
    name,
    image,
    position,
    linkedIn,
    instagram,
    twitter,
    facebook,
    personal,
    github,
    youtube,
    email,
  } = cardInfo;

  return (
    <div className={`${classes.card} text-white`}>
      <div className={classes.imageWrapper}>
        <img
          className={classes.image}
          alt={name}
          src={image || defaultAvtar}
        />
        <div className={classes.overlay}>
          <div
            className={`${classes.social} flex flex-row justify-center`}
          >
            {instagram && (
              <a
                href={instagram}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-instagram`}
              >
                <FaInstagram />
              </a>
            )}
            {facebook && (
              <a
                href={facebook}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-facebook`}
              >
                <FaFacebook />
              </a>
            )}
            {linkedIn && (
              <a
                href={linkedIn}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-linkedin`}
              >
                <FaLinkedin />
              </a>
            )}
            {twitter && (
              <a
                href={twitter}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-twitter`}
              >
                <FaTwitter />
              </a>
            )}
            {personal && (
              <a
                href={personal}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-website`}
              >
                <FaGlobe />
              </a>
            )}
            {github && (
              <a
                href={github}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-github`}
              >
                <FaGithub />
              </a>
            )}
            {youtube && (
              <a
                href={youtube}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-youtube`}
              >
                <FaYoutube />
              </a>
            )}
            {email && (
              <a
                href={`mailto:${email}`}
                className={classes.iconButton}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${name}-email`}
              >
                <FaEnvelope />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className={classes.cardBody}>
        <div className={`${classes.name} text-white`}>{name}</div>
        <div className={`${classes.position} text-white`}>{position}</div>
      </div>
    </div>
  );
}

export default TeamCard;
